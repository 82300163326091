import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tabContent"];

  rulesetSelected(evt) {
    const target = evt.target;
    const ruleset = target.value;

    const existing = this.tabContentTarget.getElementsByClassName("active")[0];
    existing.classList.add("hidden");
    existing.classList.remove("active");

    const newTab = document.getElementById(`ruleset-${ruleset}`);
    newTab.classList.add("active");
    newTab.classList.remove("hidden");

    const addButton = document.getElementById("add-help-item");
    let url = addButton.getAttribute("href");

    if (url.indexOf("?ruleset_id=") !== -1) {
      // replace
      url = url.replace(/\?ruleset_id=.*/, `?ruleset_id=${ruleset}`);
    } else {
      // Add a new variable
      url += `?ruleset_id=${ruleset}`;
    }

    addButton.setAttribute("href", url);
    console.log(addButton);
  }
}
